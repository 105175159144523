import React, { useState, useEffect } from 'react';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { MenuData } from '../../components/contentsParts/DbData';

const Footer = () =>{
    const [isScrolled, setIsScrolled] = useState(false);
    const [ language, setLanguage ] = useState('')
    const [ menuData, setMenuData] = useState([]);
    const [ token, setToken ] = useState('');

    useEffect(() => {
        const token = localStorage.getItem('token');
        
        if(token){
            setToken(token);
        }
        const lStrage = localStorage.getItem('Lang');
        const data = JSON.parse(lStrage);
        const langStrage =data.value;
        switch(langStrage){
            case 'zhCn': setLanguage(langStrage);
            break;
            case 'en': setLanguage(langStrage);
            break;
            case 'ja':
            default: setLanguage('ja');
            break;
        }
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        // スクロールイベントリスナーを追加
        window.addEventListener('scroll', handleScroll);

        // クリーンアップ関数でイベントリスナーを削除
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    },[])

    useEffect(() => {
        const getMenuFunction = async () => {
            const getData = await MenuData(1,language);
            if(getData){
                setMenuData(getData);
                try {
                    setMenuData(JSON.parse(getData));
                } catch (e) {
                }
            }
        }
        
        if (language) {
            getMenuFunction();
        }
    },[language,token]);

    const topBackLinkfunction = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    const scrollToVisible = () => {
        if(isScrolled){
            return null;
        }else{
            return '!hidden';
        }
    }
    const BottomFunction = () =>{
        return (
                    <div className="">
                        <Menu 
                            model={menuData} 
                            className="!w-auto !bg-inherit !border-none"
                            pt={{
                                root: ' ',
                                menu: '!m-0 !p-0 list-none outline-none flex flex-wrap ',
                                content: 'text-gray-600  hover:!text-blue-900 hover:font-bold hover:!bg-inherit !py-0 mb-1 hover:mb-0 text-xs',
                                menuitem: 'hover:border-b-4 hover:!border-blue-900/80 hover:!text-blue-900 hover:!border-double hover:!bg-inherit  mb-1 hover:mb-0',
                                label:'hover:!text-blue-900 ',
                                icon: 'hover:!text-gray-600 text-xs pi-fw',
                                action:'!py-0 !px-2 hover:!no-underline'
                            }}
                        />
                    </div>
                );
    }

    return(
        <>
        <footer className="md:fixed md:bottom-0 md:left-0 md:right-0 text-center bg-white text-sm text-gray-600 !shadow "
            style={{
                backgroundImage:'linear-gradient(-9deg, rgba(220, 248, 183, 0), rgba(255, 255, 255, 0) 34%, rgb(112 220 254 / 60%) 72%'
            }}
        >
            <div className="w-full flex flex-col justify-between items-center w-100 justify-between max-w-screen-xl mx-auto" >
                {/* Bottom menu */}
                <div className="hidden md:flex items-center content-center !max-h-15 !min-h-6 pt-2">
                    <BottomFunction />
                </div>
                <span className="text-xs pt-2 md:pt-1 pb-1">
                    Copyright © 2024 kougakunavi.jp All Rights Reserved.
                </span>
            </div>
        </footer>
        <Button
            className={`!fixed bottom-[110px] md:bottom-[80px] right-4 
                !rounded-full !bg-sky-900 opacity-75 
                !pl-3 !pt-2
                !text-xs
                ${scrollToVisible()}`}
            label='Top'
            icon='pi pi-angle-double-up'
            onClick={topBackLinkfunction}
        />
        </>
    )
}
export default Footer